import React, { useState } from "react";

const HiddenScreenToken = ({ hiddenValue }) => {
    const [isVisible, setIsVisible] = useState(false);
    const revealValue = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div>
            <h6>Reveal Hidden Login & Token</h6>
            {/* Step 4: Add a button to reveal the hidden value */}
            <button onClick={revealValue} className='connect-button'>
                {isVisible ? "Hide" : "Show device login token"}
            </button>
            {/* Step 5: Conditionally display the hidden value */}
            {isVisible && (<div className="hiddenScreenToken">
                <p className="secret">remember to keep login and token safe.</p>
                <p>login: {hiddenValue.login}</p>
                <p>token: {hiddenValue.token}</p>
                </div>
            )}

        </div>
    );
};

export default HiddenScreenToken;

