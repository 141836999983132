// Preview.js
import React, { useState, useEffect, useRef } from 'react';
import { FaFilm } from 'react-icons/fa';

const Preview = ({ images, durations }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const progressInterval = useRef(null);
    const startTime = useRef(null);

    useEffect(() => {
        if (isPlaying && images.length > 0) {
            const totalDuration = durations.reduce((acc, val) => acc + val, 0) * 1000;

            const updateProgress = () => {
                const elapsedTime = Date.now() - startTime.current;
                const totalProgress = (elapsedTime / totalDuration) * 100;
                setProgress(Math.min(totalProgress, 100));

                if (totalProgress >= 100) {
                    clearInterval(progressInterval.current);
                    setIsPlaying(false);
                    setProgress(100);
                }
            };

            startTime.current = Date.now();
            progressInterval.current = setInterval(updateProgress, 100);

            return () => clearInterval(progressInterval.current);
        }
    }, [isPlaying, images.length, durations]);

    useEffect(() => {
        let imageInterval;

        if (isPlaying && images.length > 0) {
            imageInterval = setInterval(() => {
                setCurrentIndex(prevIndex => {
                    const nextIndex = (prevIndex + 1) % images.length;
                    return nextIndex;
                });
            }, durations[currentIndex] * 1000);
        }

        return () => clearInterval(imageInterval);
    }, [isPlaying, currentIndex, images.length, durations]);

    const handlePlay = () => {
        setCurrentIndex(0);
        setProgress(0);
        setIsPlaying(true);
    };

    const handleStop = () => {
        setIsPlaying(false);
        setProgress(0);
        clearInterval(progressInterval.current);
    };

    if (images.length === 0) {
        return <div className="preview-area">No images to preview</div>;
    }

    return (
        <div className="preview-area">
            <h3>Preview</h3>
            {images[currentIndex].match(/\.(jpeg|jpg|png)$/i) ?
                (<img src={images[currentIndex]} alt={`Preview ${currentIndex + 1}`} className="preview-image" />) :
                (<div style={{maxWidth: "480px", margin: "0 auto", position: "relative"}}>
                    <video src={images[currentIndex]} alt={`Preview ${currentIndex + 1}`} className="preview-image" />
                    <FaFilm style={{position: "absolute", top: "8px", left: "8px"}} />
                </div>)
            }
            <div className="preview-duration">
                {`Showing image ${currentIndex + 1} of ${images.length} for ${durations[currentIndex]} seconds`}
            </div>
            <div className="preview-controls">
                <button onClick={handlePlay} disabled={isPlaying}>Play</button>
                <button onClick={handleStop} disabled={!isPlaying}>Stop</button>
            </div>
            <div className="progress-bar-container">
                <div className="progress-bar">
                    <div className="progress" style={{ width: `${progress}%` }}></div>
                </div>
            </div>
        </div>
    );
};

export default Preview;
